import createReducer from "lib/createReducer";
import * as types from "ContentManager/actions/types";

const DEFAULT_STATE = {
    important: [],
    waiting: [],
    complete: [],
    tabbedProjectLists: [],
};

export const projectList = createReducer(
    DEFAULT_STATE,
    {
        [types.SET_PROJECT_LISTS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState.important = [];
                newState.waiting = [];
                newState.complete = [];
            } else {
                if (action.data.hasOwnProperty('important')) {
                    newState.important = action.data.important;
                }
                if (action.data.hasOwnProperty('waiting')) {
                    newState.waiting = action.data.waiting;
                }
                if (action.data.hasOwnProperty('complete')) {
                    newState.complete = action.data.complete;
                }

            }
            
            return newState;
        },

        [types.SET_TABBED_PROJECT_LISTS](state, action) {
            let newState = Object.assign({}, state);
            if (action.data === null || action.data === undefined) {
                newState.tabbedProjectLists = [];
            } else {
                newState.tabbedProjectLists = action.data;
            }
            
            return newState;
        },

    }
);
