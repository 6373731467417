import { executeBasicNetworkCall } from "lib/Utils";
import * as types from 'ContentManager/actions/types.js';
import * as MessageActions from "actions/messages";

export function getProjectLists(is_all = false, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_project_lists",
            JSON.stringify({ is_all }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setProjectLists(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getTabbedProjectLists(is_all = false, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_tabbed_project_lists",
            JSON.stringify({ is_all }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false);
                    } else {
                        dispatch(setTabbedProjectLists(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function createProject(content_type = '', callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "create_new_project",
            JSON.stringify({ content_type: content_type }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function archiveProject(project_id = '', callback = () => { }) {
    return (dispatch, getState) => {
        // executeBasicNetworkCall(
        //     "archive_project",
        //     JSON.stringify({ project_id: project_id }),
        //     "application/json",
        //     "POST",
        //     getState,
        //     (reqq) => {
        //         if (reqq.responseText != null) {
        //             const x = JSON.parse(reqq.responseText);
        //             if (x.error) {
        //                 callback(false, x.message);
        //             } else {
        //                 callback(true, x.data);
        //             }
        //         }
        //     },
        //     (err) => console.log(err),
        //     false,
        //     (status) => console.log(status),
        // );
    };
}

export function getProjectDetails(project_id = '', callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_project_details",
            JSON.stringify({ project_id: project_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setProjectDetails(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function editProjectDetails(
    project_id = null,
    name,
    author_id,
    author_due_date,
    publish_date,
    directions,
    // tags
    is_customer_exclusive,
    state_ids,
    br_district_ids,
    br_region_ids,
    content_category_ids,
    crop_ids,
    product_ids,
    is_featured,
    callback = () => { }
) {
    let json = JSON.stringify({
        project_id,
        name,
        author_id,
        author_due_date,
        publish_date,
        directions,
        is_customer_exclusive,
        state_ids,
        br_district_ids,
        br_region_ids,
        content_category_ids,
        crop_ids,
        product_ids,
        is_featured,
    });
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "edit_project_details",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.message);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        )
    }
}

export function sendProjectToAuthor(
    project_id = null,
    callback = () => { }
) {
    let json = JSON.stringify({ project_id });
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "send_project_to_author",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.message);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        )
    }
}

export function sendProjectToReviewTeam(
    project_id = null,
    callback = () => { }
) {
    let json = JSON.stringify({ project_id });
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "send_project_to_review_team",
            json,
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.message);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        )
    }
}

export function getListOfAuthors(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_list_of_authors",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

function setTabbedProjectLists(data = null) {
    return {
        type: types.SET_TABBED_PROJECT_LISTS,
        data: data,
    };
}

function setProjectLists(data = null) {
    return {
        type: types.SET_PROJECT_LISTS,
        data: data,
    };
}

export function setProjectDetails(data = null) {
    return {
        type: types.SET_PROJECT_DETAILS,
        data: data,
    };
}

export function approveProjectForPublish(project_id, publish_now, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "approve_project_for_publish",
            JSON.stringify({ project_id, publish_now }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function cancelProjectScheduleForPublish(project_id, callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "cancel_project_schedule_for_publish",
            JSON.stringify({ project_id }),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                        dispatch(MessageActions.setError(x.message));
                    } else {
                        callback(true, x.data);
                        dispatch(MessageActions.setSuccess(x.message));
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

export function getBadgesForContent(callback = () => { }) {
    return (dispatch, getState) => {
        executeBasicNetworkCall(
            "get_badges_for_content",
            JSON.stringify({}),
            "application/json",
            "POST",
            getState,
            (reqq) => {
                if (reqq.responseText != null) {
                    const x = JSON.parse(reqq.responseText);
                    if (x.error) {
                        callback(false, x.message);
                    } else {
                        dispatch(setContentBadges(x.data));
                        callback(true, x.data);
                    }
                }
            },
            (err) => console.log(err),
            false,
            (status) => console.log(status),
        );
    };
}

function setContentBadges(data = null) {
    return {
        type: types.SET_CONTENT_BADGES,
        data,
    };
}
